@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui,
    helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
}

.top-nav {
  z-index: 1;
}

a#isi {
  @apply text-green underline underline-offset-1 hover:text-opacity-80 transition duration-75;
}

footer a {
  @apply text-lg lg:text-base underline lg:no-underline underline-offset-1 text-black font-bold;
}

#isi-fixed {
  background: white;
  top: 85%;
  height: 100vh;
  overflow-y: scroll;
}

#isi-fixed,
#isi {
  font-size: 18px;
}

#isi .see-more {
  display: none;
}

#isi.fixed .see-more {
  display: block;
}

#eligible-modal p {
  font-size: 18px;
  color: #555;
}

#isi b, #isi-fixed b {
  @apply text-green font-bold;
}

#isi a, #isi-fixed a {
  @apply underline text-green;
}

#isi b.text-black, #isi-fixed b.text-black {
  color: black !important;
}

.ul1 {
  @apply list-disc ml-6;
}

